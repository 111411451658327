<template>
  <div class="hongbao">
    <headerTop title="福利中心" @handleReadme="handleReadme" />
    <div class="taps">
      <div :class="index == 1 ? 'tap active' : 'tap'" @click="handleIndex(1)">cdk兑换</div>
      <div :class="index == 2 ? 'tap active' : 'tap'" @click="handleIndex(2)">等级宝箱</div>
      <div :class="index == 3 ? 'tap active' : 'tap'" @click="handleIndex(3)">签到宝箱</div>
    </div>
    <div class="cdkBox" v-if="index == 1">
      <h3>请输入您的<span :style="{ color: $main }">CDK兑换码</span></h3>
      <div class="input_wrap">
        <h5><input type="text" v-model.trim="kami" placeholder="请输入CDK兑换码" /><button @click="getRedPacket">兑换</button></h5>
        <div class="recharge">
          <div class="cont">
            <i class="el-icon-s-finance"></i>
            <p>使用卡密代码充值<br /><span>联系平台客服（2199193281）使用卡密充值获得更多优惠活动</span></p>
          </div>
          <button @click="toRecharge">充值</button>
        </div>
      </div>
    </div>
    <div class="box_wrap" v-if="index == 2 || index == 3">
      <div class="currentLevelBox" v-if="index == 2">
        <div class="currentLevel">
          <div class="level">当前等级：{{ currentGrade }}级</div>
          <!-- <div class="txt">级</div> -->
        </div>
        <div class="press">
          <!-- <div class="currentmoney">综合消费：{{ (userInfo.gradeConsumeValue || 0) + '/' + nextGameCondition.rulerConsumeBlend }}</div> -->
          <div class="currentamount">充值：{{ (userInfo.gradeRechargeValue||0) + '/' + (nextGameCondition.rulerRecharge?nextGameCondition.rulerRecharge:0) }}</div>
        </div>
      </div>
      <div class="box_list" v-if="index == 2">
        <boxCard v-for="(item, index) in boxList" :boxItem="item" :key="index" :isMe="curTab == 3" @update="delOffBox" :currentGrade="currentGrade" :openModel="'rechargeLevelBox'" />
      </div>
      <div class="box_list" v-if="index == 3">
        <boxCard v-for="(item, index) in boxList" :boxItem="item" :key="index" :isMe="curTab == 3" @update="delOffBox" :currentGrade="currentGrade" :openModel="'rechargeFreeBox'" />
      </div>
      <div v-if="!boxList.length" class="over_text pc-show">--暂无数据--</div>
      <el-pagination background class="pc-show" layout="prev, pager, next" :hide-on-single-page="true" :total="boxTotal" :current-page="boxPage.page" :page-size="boxPage.size" @current-change="queryBoxList" />
    </div>
    <div class="result_box" v-if="isResult">
      <div class="result animate__animated animate__fadeInDown" ref="result">
        <div class="lvbu"></div>
        <div class="result_title">领取成功</div>
        <div class="result_money">
          恭喜获得
          <price  :price="data" :size="$store.state.mode == 'mobile' ? 'small' : 'large'" />
        </div>
        <div class="result_btn">
          <div class="result_btn_B btn_click" @click="handleOk">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { receiveRedPacket, getUserInfo, getBoxList, getRechargeGreadeNorm, gameConditionById } from '@/api/index';
import boxCard from '@/components/boxCard.vue';
import { throttle } from '@/utils/utils';
export default {
  components: { boxCard },
  data() {
    return {
      currentGrade: 0,
      curTab: 1,
      kami: '',
      isResult: false,
      data: null,
      index: 1,
      boxPage: {
        page: 1,
        size: 20,
      },
      boxForm: {
        boxId: '',
        name: '',
        owner: '',
        orderByFie: '6',
        maxPrice: null,
        minPrice: null,
        gameMouldId: 8,
      },
      boxList: [],
      boxTotal: 0,
      scrollDom: null,
      isOver: '上拉加载更多',
      tabTop: 0,
      levelList: [],
      levelPage: {
        page: 1,
        size: 20,
      },
      userInfo: {},
      nextGameCondition: {},
    };
  },

  mounted() {
    window.addEventListener('popstate', () => {
      this.isResult = false;
    });
    this.getTabsTop();
    this.getLevelList();
  },
  methods: {
    handleReadme() {
      // console.log(111111);
      this.$bus.$emit('is_illustrate', true);
      this.$bus.$emit('nr_illustrate', { cont: 'hongbao' });
    },
    findLevel(i) {
      if(this.levelList.length==i){
        console.log(1123);
        
        this.nextGameCondition.rulerRecharge='max'
        return false
      }
      gameConditionById(this.levelList[i].gameConditionId).then(res => {
        // this.rechargeInfo = res?.data?.data || {};
        // console.log('保准', res?.data?.data);

        if (this.userInfo.gradeConsumeValue >= res?.data?.data.rulerConsumeBlend && this.userInfo.gradeRechargeValue >= res?.data?.data.rulerRecharge) {
          // console.log('符合' + res?.data?.data.name);
          this.currentGrade = i;
          this.findLevel(i + 1);
        } else {
          // console.log('不符合' + res?.data?.data.name);
          this.nextGameCondition = res?.data?.data;
        }
      });
    },
    getLevelList() {
      getRechargeGreadeNorm(this.levelPage).then(res => {
        this.levelList.push(...res.data.data.list);
        this.levelList = this.levelList.sort((a, b) => {
          return a.grade - b.grade;
        });
        console.log('充值等级', this.levelList);
        if (res.data.data.list.length == this.levelPage.size) {
          this.levelPage.page++;
          this.getLevelList();
        } else {
          getUserInfo().then(res => {
            if (res?.data?.code == 200) {
              this.userInfo = res?.data?.data || {};
              this.$store.commit('USER_INFO', res?.data?.data);
              console.log(this.userInfo);
              this.findLevel(0);
            } else {
              this.$store.commit('LOGIN_IS_SHOW', true);
            }
          });
        }
      });
    },
    getTabsTop() {
      let timer = setTimeout(() => {
        const tabs = document.querySelector('#tabs');
        if (!tabs) return;
        const headerH = 60;
        this.tabTop = tabs.getBoundingClientRect().top - headerH;
        clearTimeout(timer);
      }, 500);
    },
    handleScroll: throttle(function (e) {
      if (this.$store.state.mode == 'pc') return;
      this.abs = e.target.scrollTop > this.tabTop;
      const { scrollTop, clientHeight, scrollHeight } = e.srcElement;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (this.isOver == '上拉加载更多') {
          this.boxPage.page++;
          this.queryBoxList();
        }
      }
    }, 100),
    handleIndex(i) {
      this.index = i;
      this.$router.replace(`/hongbao?tab=${i}`);
      if (i == 2) {
        this.boxForm.gameMouldId = 8;
        this.boxList = [];
        this.queryBoxList(1);
      } else if (i == 3) {
        this.boxForm.gameMouldId = 9;
        this.boxList = [];
        this.queryBoxList(1);
      }
    },
    delOffBox(id) {
      if (this.$store.state.mode == 'pc') {
        this.queryBoxList();
      } else {
        const list = [...this.boxList];
        this.boxList = list.filter(item => item.boxId != id);
      }
    },
    queryBoxList(page) {
      this.isOver = '加载中';
      if (page && typeof page == 'number') this.boxPage.page = page;
      const params = { ...this.boxForm, ...this.boxPage };
      Object.keys(params).forEach(key => {
        if (params[key] === '' || params[key] === undefined || params[key] === null) delete params[key];
      });
      getBoxList(params).then(res => {
        if (res?.data?.code != 200) return;
        const { list, total } = res?.data?.data || {};
        list.map(item => (item.boxNum = 0));
        if (this.$store.state.mode == 'pc') {
          this.boxList = [...list];
          this.boxTotal = total || 0;
        } else {
          if (this.boxPage.page == 1) {
            this.boxList = [...list];
          } else {
            this.boxList.push(...list);
          }
          this.isOver = list?.length < this.boxPage.size ? '暂无更多' : '上拉加载更多';
        }
      });
    },

    playAudio() {
      this.$store.commit('playAudio');
    },
    toRecharge() {
      this.playAudio();
      this.$router.push('/recharge');
    },
    getRedPacket() {
      this.playAudio();
      if (this.$store.state.LOGIN_IS_SHOW) {
        this.$router.push('/login');
        return;
      }
      if (!this.kami) {
        this.$message({ message: '请输入CDk兑换码', type: 'warning' });
        return;
      }
      receiveRedPacket(this.kami).then(res => {
        if (res?.data?.code == 200) {
          this.isResult = true;
          getUserInfo().then(res => {
            if (res?.data?.code == 200) {
              this.$store.commit('USER_INFO', res?.data?.data);
            }
          });
          this.data = res?.data?.data;
          this.$message({
            type: 'success',
            message: '领取成功',
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning',
          });
        }
      });
      this.kami = '';
    },
    handleOk() {
      this.playAudio();
      const element = this.$refs.result;
      if (element) element.classList += ' animate__fadeOutDown';
      let timer = setTimeout(() => {
        this.isResult = false;
        clearTimeout(timer);
      }, 350);
    },
  },
  watch: {
    '$store.state.mode': {
      handler(val) {
        if (val == 'pc' || this.curTab == 4) {
          if (!this.scrollDom) return;
          this.scrollDom.removeEventListener('scroll', this.handleScroll);
        } else {
          this.scrollDom = document.querySelector('#main');
          if (!this.scrollDom) return;
          this.getTabsTop();
          this.scrollDom.addEventListener('scroll', this.handleScroll);
        }
      },
      immediate: true,
    },
    '$route.query': {
      handler(val) {
        const tab = val?.tab || '1';
        this.index = tab;

        if (tab == 1) return;
        if (tab == 2) {
          this.boxForm.gameMouldId = 8;
        }
        if (tab == 3) {
          this.boxForm.gameMouldId = 9;
        }
        // if (tab == 3) {
        //   this.boxPage.status = [0];
        //   delete this.boxPage.isPlayerBox;
        // } else {
        //   if (tab == 1) this.boxPage.isPlayerBox = 0;
        //   if (tab == 2) this.boxPage.isPlayerBox = 1;
        //   delete this.boxPage.status;
        // }
        this.queryBoxList(1);
        if (!this.abs || !this.scrollDom) return;
        this.scrollDom.scrollTo({ top: this.tabTop, behavior: 'smooth' });
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    if (!this.scrollDom) return;
    this.scrollDom.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
.currentLevelBox {
  .sc(20px, #fff);
  // margin-bottom: 10px;
  width: 100%;
  max-width: 650px;
  margin: 0 auto 10px;
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;
  @media @max750 {
    .sc(16px, #fff);
  }
  .currentLevel {
    // width: 200px;
    display: flex;
    align-items: center;
  }

  .press {
    // flex: 1;
    // display: flex;
    text-align: right;
  }
}

.box_wrap {
  overflow: hidden;
  margin: 20px 0 0;

  .box_list {
    .grid(5, 14px);
  }

  @media @max750 {
    margin: 10px 12px 0;

    .box_list {
      .grid(2, 6px);
    }
  }

  .el-pagination {
    margin-top: 40px;
    text-align: center;
  }
}

.hongbao {
  margin: 0 @pcSpace;

  @media @max750 {
    margin: 0 14px;
  }

  .taps {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 35%;
    min-width: 342px;
    margin: 0 auto;

    @media @max750 {
      width: 60%;
      padding-bottom: 20px;
    }

    .tap {
      .sc(20px, #fff);
      padding: 10px 15px;
      border-radius: 10px;
      border: 1px solid #606365;
      background: rgba(#606365, 0.1);
      cursor: pointer;
    }

    .active {
      border: 1px solid @main;
      background: rgba(@main, 0.1);
    }
  }

  .cdkBox {
    & > h3 {
      .sc(30px, #fff);

      @media @max750 {
        display: none;
      }
    }

    .input_wrap {
      .flex-a-center;

      @media @max750 {
        flex-direction: column;
      }

      & > h5 {
        .flex-a-center;
        .wh(720px, 54px);
        border-radius: 10px;
        font-weight: normal;
        border: 1px solid #606365;

        @media @max750 {
          .wh(100%, 36px);
          border-radius: 4px;
        }

        input {
          flex: 1;
          border: none;
          .sc(18px, #fff);
          background: none;
          padding-left: 24px;

          @media @max750 {
            font-size: 14px;
            padding-left: 10px;
          }
        }

        button {
          .sc(18px, #fff);
          .btn-style(100px, 54px);
          outline: none;
          border-radius: 10px;
          border: 1px solid @main;
          background: rgba(@main, 0.1);

          @media @max750 {
            .wh(66px, 38px);
            font-size: 14px;
            line-height: 38px;
            border-radius: 4px;
            .btn-bg;
          }
        }
      }

      .recharge {
        .flex-a-center;
        margin-left: 227px;
        padding: 30px 20px;
        border-radius: 10px;
        border: 1px solid #606365;

        @media @max750 {
          padding: 0;
          border: none;
          margin-left: 0;
          margin-top: 30px;
          flex-direction: column;
        }

        .cont {
          display: flex;

          i {
            color: @main;
            font-size: 20px;
            padding-right: 6px;

            @media @max750 {
              font-size: 18px;
            }
          }

          p {
            .sc(20px, #fff);
            line-height: 26px;

            @media @max750 {
              font-size: 16px;
              line-height: 20px;
            }

            span {
              font-size: 14px;
              padding-top: 6px;
              line-height: 20px;
              display: inline-block;
            }
          }
        }

        & > button {
          .sc(18px, #fff);
          margin-left: 30px;
          font-weight: bold;
          .btn-style(180px, 50px);
          background: url(../../assets/image/loginbtn.png) no-repeat;
          background-size: 100% 100%;
          border: none;

          @media @max750 {
            font-size: 16px;
            margin-left: 0;
            margin-top: 20px;
            .wh(100%, 42px);
            line-height: 40px;
            .btn-bg;
          }
        }
      }
    }
  }
}

.result_box {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 31;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);

  @media @max750 {
    background: none;
  }

  .flex-center;

  .result {
    width: 40%;
    margin: 0 auto;
    padding: 32px 0;
    background: #101115;
    border-radius: 23px;
    position: relative;
    opacity: 1;

    @media @max750 {
      width: 90%;
      padding: 25px 0;
    }

    .lvbu {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;

      @media @max750 {
        height: 110px;
      }

      border-radius: 23px 23px 23px 23px;
      height: 130px;
      background: linear-gradient(-218deg, rgba(@main, 0.2) 0%, rgba(32, 29, 48, 0) 38%, rgba(248, 125, 81, 0) 100%);
    }

    .result_title {
      width: 100%;
      text-align: center;
      font-size: 28px;
      color: #ffff;

      @media @max750 {
        font-size: 20px;
      }
    }

    .result_center {
      width: 100%;
      margin: 0 auto;
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }

    .result_money {
      width: 90%;
      height: 56px;
      border-radius: 35px 35px 35px 35px;
      opacity: 1;
      margin: 0 auto;
      margin-top: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;

      @media @max750 {
        height: 40px;
      }
    }

    .result_btn {
      width: 100%;
      margin: 14px 0 0 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 21px;

      .result_btn_B {
        .btn-bg;
        width: 196px;
        height: 56px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        @media @max750 {
          width: 140px;
          height: 30px;
          font-size: 15px;
        }
      }
    }
  }
}
</style>
